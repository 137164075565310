import React, {useState} from 'react';
import YouTube from 'react-youtube';
import Div100vh from 'react-div-100vh'
import {GatsbyImage} from "gatsby-plugin-image";
import Link from "gatsby-link";
import {localeLink} from "../util";
import MyPortableText from "./MyPortableText";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import CookiePopup from "./CookiePopup";

const Landing = ({video, image, title, location, startseitebtntext, cookiebtn, cookietext1, cookietext2, cookiedata}) => {

    const [videoVisible, setVideoVisible] = useState(false)

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 0,
            playsinline: 1,
            disablekb:1,
            mute: 1,
            loop: 1,
            playlist: video,
            showinfo: 0
        },
    };

    return (
        <div className="landing__outer div100">
            <GatsbyImage className={"fullbackgrounddiv"} image={image} alt={"Work by Ijob Brandstätter"}></GatsbyImage>
            {video ? <div className="video-container">
                <div className={"iframeoverlay"}></div>
                <YouTube videoId={video} opts={opts} onReady={(e) => {console.log(e.target.playVideo())}} onStateChange={() => {console.log('onStateChange')}} onPlay={() => {console.log('onPlay');setVideoVisible(true)}} className={videoVisible ? "fullbackgrounddiv video visible" : "fullbackgrounddiv video"}/>
            </div> : <></>}
            <div className="landing__inner">
                <div className="animation__outer animation__outer__headline h1-animated">
                    <MyPortableText className="color-light landing__headline" value={title}></MyPortableText>
                </div>
                <div className="animation__outer hoverable">
                    <div className={"btn__landing__outer"}>
                        <AnchorLink to={localeLink("#videography", location)} className="btn__landing btn btn-red hover-moveup">{startseitebtntext}</AnchorLink>
                    </div>
                </div>
            </div>
            <CookiePopup location={location} cookietext1={cookietext1} cookietext2={cookietext2} cookiedata={cookiedata} cookiebtn={cookiebtn}></CookiePopup>
        </div>
    );
}

export default Landing;


/*
<YoutubeBackground opts={opts} onPlay={() => {setVideoVisible(true)}} className={videoVisible ? "fullbackgrounddiv video visible" : "fullbackgrounddiv video"} videoId={video}>
            </YoutubeBackground>

 <YoutubeBackground opts={opts} className={"fullbackgrounddiv"} videoId={video}>
            </YoutubeBackground>
 */
