import React, {useEffect, useRef, useState} from "react";
import MyPortableText from "./MyPortableText";
import {GatsbyImage} from "gatsby-plugin-image";
import {useInView} from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Fact = ({fact}) => {

    const [ref, inView] = useInView()
    const [count, setCount] = useState("0")

    let number = fact ? fact.prozent : "";
    let duration = 1;

    useEffect(() => {
        if (inView) {
            let start = 0;
            // first three numbers from props
            const end = parseInt(number.substring(0,3))
            // if zero, return
            if (start === end) return;

            // find duration per increment
            let totalMilSecDur = parseInt(duration);
            let incrementTime = (totalMilSecDur / end) * 1000;

            // timer increments start counter
            // then updates count
            // ends if start reaches end
            let timer = setInterval(() => {
                start += 1;
                setCount(String(start) + number.substring(3))
                if (start === end) clearInterval(timer)
            }, incrementTime);
        } else {
            //control.start("hidden");
        }
    }, [inView, number, duration]);

    return <div className="number__container" ref={ref}>
        <h1 className={"centered color-dark noshadow"}>{count}%</h1>
        <MyPortableText value={fact._rawContent}></MyPortableText>
    </div>
}

export default Fact;

/*
                        <div className="cursor" ref={cursorRef}></div>

 */
