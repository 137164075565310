import React, {useEffect, useRef, useState} from "react";
import Fact from "./Fact";
import { useInView } from "react-intersection-observer";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import MyPortableText from "./MyPortableText";
import {useBreakpoint} from "gatsby-plugin-breakpoints";

const FactContainer = ({facts, title}) => {

    const breakpoint = useBreakpoint();
    const [currentSlide, changeCurrentSlide] = useState(1);


    return (
        <div className="facts__outer">
            <div className="cornerrow top">
                <div className="corner left top"></div>
                <div className="corner right top"></div>
            </div>
    <div className="testimonials__inner">
        <Swiper
            className="mySwiper fact__swiper"
            loop={true}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            onSwiper={(swiper) => changeCurrentSlide(swiper.realIndex+1)}
            onSlideChange={(swiper) => changeCurrentSlide(swiper.realIndex+1)}
            slidesPerView={breakpoint.sm ? 1 : 3}>
            {facts.map((el, index) => {
                return (
                    <SwiperSlide className={"fact__slide"}>
                        <Fact fact={el}></Fact>
                    </SwiperSlide>
                );
            })}
            <p className={"centered color-lightgrey"}>({currentSlide}/{facts.length})</p>
        </Swiper>
    </div>

            <div className="cornerrow bottom">
                <div className="corner left bottom"></div>
                <div className="corner right bottom"></div>
            </div>
        </div>
    )
}

export default FactContainer;


/*

<div className="facts__inner">
                {facts.map((el, index) => {
                    return (<Fact  fact={el} reversed={index%2 ? true : false}></Fact>);
                })}
            </div>

<div ref={dotOutline} className="cursor-dot-outline"></div>

mouseEnterEvent={mouseEnterEvent} mouseLeaveEvent={mouseLeaveEvent}



const delay = 10;
    const cursorVisible = useRef(false);

    const endX = useRef(window.innerWidth/2);
    const endY = useRef(window.innerHeight/2);
    const _x = useRef(0);
    const _y = useRef(0);

    const requestRef = useRef(null);

    const dotOutline = useRef(null);

    const toggleCursorVisibility = () => {
        if(cursorVisible.current){
            dotOutline.current.style.opacity = .8;
            dotOutline.current.style.transform = "translate(-50%, -50%) scale(3)";
        }
        else{
            dotOutline.current.style.opacity = 0;
            dotOutline.current.style.transform = "translate(-50%, -50%) scale(1)";

        }
    };

    const mouseEnterEvent = () => {
        console.log('shoudbevisible');
        cursorVisible.current = true;
        toggleCursorVisibility();
    }
    const mouseLeaveEvent = () => {
        cursorVisible.current = false;
        toggleCursorVisibility();
    }

    const mouseMoveEvent = e => {
        //cursorVisible.current = false;
        //toggleCursorVisibility();
        endX.current = e.pageX;
        endY.current = e.pageY;
    }

    const animateDotOutline = () =>{
        _x.current += (endX.current - _x.current) / delay;
        _y.current += (endY.current - _y.current) / delay;

        dotOutline.current.style.top = _y.current + "px";
        dotOutline.current.style.left = _x.current + "px";

        requestRef.current = requestAnimationFrame(animateDotOutline);
    }

    useEffect(() => {
        document.addEventListener('mousemove', mouseMoveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        document.addEventListener('mouseleave', mouseLeaveEvent);

        animateDotOutline();
        return () => {
            document.removeEventListener('mousemove', mouseMoveEvent);
            document.removeEventListener('mouseenter', mouseEnterEvent);
            document.removeEventListener('mouseleave', mouseLeaveEvent);

            cancelAnimationFrame(requestRef.current);
        }
    })
 */
