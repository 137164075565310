import React, {useState, useRef} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "./MyPortableText";
import {localeLink} from "../util";


const AboutContainer = ({image, text, metadata, location, title}) => {

    console.log(text);

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const contentRef = useRef();

    return (
        <div className={`about__outer`} id={"about"} >
            <div className={`bg-black absolute-bg ${open ? "expanded" : ""}`}></div>
            <div className="about__inner">
                <h2 className={"color-light mobile"}>{title}</h2>
                <div className="image">
                    <GatsbyImage image={image.asset.gatsbyImageData} alt={"Ijob Brandstätter"}></GatsbyImage>
                </div>
                <div className="text">
                    <h2 className={"color-light desktop"}>{title}</h2>
                    <p className={"color-light desktopblock"}>
                        <MyPortableText value={text}></MyPortableText>
                    </p>
                    <p className={"color-light mobile"}>
                        {text[0].children[0].text}
                    </p>
                    <p className={"color-light mobile collapsible"} ref={contentRef} style={open ? {height: contentRef.current.scrollHeight + "px"} : {height: "0px"}}>
                        <MyPortableText value={text.slice(1)}></MyPortableText>
                    </p>
                    <div className="centered mobile">
                        <p className="color-light hover-underline" onClick={toggle}>
                            {open ? metadata.showless : metadata.showmore}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutContainer;

/*
                        <MyPortableText value={text.slice(1)}></MyPortableText>

 */
