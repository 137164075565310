import React, {useRef, useState} from "react";
import Icon from "./Icon";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {Link} from "gatsby";
import {localeLink} from "../util";
import gsap from 'gsap';

const Service = ({service, delay, location}) => {

    const boxVariant = {
        visible: {opacity: 1, y: 0, transition: {
                ease: "easeInOut",
                duration: .5,
                delay: delay*0.2
            } },
        hidden: {opacity: 0, y: 100, transition: {
                ease: "easeInOut",
                duration: .5
            } },
    }

    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            //control.start("hidden");
        }
    }, [control, inView]);

    useEffect(() => {
        gsap.utils.toArray(".service__outer").forEach((el) => {
            const video = el.querySelector("video.video-element"),
                setX = gsap.quickTo(video, "x", { duration: 0.6, ease: "power3" }),
                setY = gsap.quickTo(video, "y", { duration: 0.6, ease: "power3" }),
                align = (e) => {
                    const rect = el.getBoundingClientRect(),
                        top = rect.top,
                        left = rect.left;
                    setX(e.clientX - left);
                    setY(e.clientY - top);
                },
                startFollow = () => document.addEventListener("mousemove", align),
                stopFollow = () => document.removeEventListener("mousemove", align),
                fade = gsap.to(video, {
                    autoAlpha: 1,
                    ease: "none",
                    paused: true,
                    onReverseComplete: stopFollow
                });

            el.addEventListener("mouseenter", (e) => {
                fade.play();
                startFollow();
                align(e);
            });

            el.addEventListener("mouseleave", () => {
                fade.reverse();
            });
        });
    }, []);



    return (
        <motion.div ref={ref}
                    variants={boxVariant}
                    initial="hidden"
                    animate={control}  className="service__outer">
            <video className={`video-element hover-video`} autoPlay loop muted>
                <source src={service?.video?.asset?.url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className={"service__inner"}>
                <div className="iconbg bg-red">
                    <Icon icon={service.icon} color="ffffff"></Icon>
                </div>
                <h4 className={"color-black"}>{service.headline}</h4>
                <p className={"smaller"}>{service.text}</p>
                <div className="btn__service__container">
                    <Link to={localeLink("/contact", location)} state={{ betreff: service.headline }} className={"btn btn-red btn__service hover-moveup"}>{service.btntext}</Link>
                </div>
            </div>
        </motion.div>
    )
}

export default Service;
