import React, {useState} from 'react';
import YouTube from "react-youtube";

const YTVideo = ({id}) => {

    const [videoVisible, setVideoVisible] = useState(false);

    const opts = {
        playerVars: {
            controls: 0,
            playsinline: 1,
            showinfo: 0,
            rel: 0
        },
    };

    return <YouTube videoId={id} opts={opts} className={videoVisible ? "yt-vid visible" : "yt-vid"} onReady={() => {setVideoVisible(true)}}/>;
}

export default YTVideo;
