import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {Link} from "gatsby";
import {localeLink} from "../util";


const ContactButton = ({location, contact}) => {

    const [button, setButton] = useState(false)

    const toggleContactButton = () => {
        if (window.scrollY >= 100) {
            setButton(true)
        } else {
            setButton(false)
        }
    }

    useEffect(() => {
        toggleContactButton();
        window.addEventListener("scroll", toggleContactButton);
    });

    return (
        <Link to={localeLink('/contact', location)} className={`btn mobile btn-fixed hover-moveup ${button ? "btn-visible" : "btn-invisible"}`}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={"hover-moveup"}
                 viewBox="0 0 720 720">
                <circle cx="360" cy="360" r="360" className={"svg-fill-red"}/>
                <path d="M361.2,494.3c-38.2,0-76.4,0.1-114.5,0c-28-0.1-49.2-16.5-55.1-42.5c-1.1-4.8-1.4-9.9-1.4-14.9
	c-0.1-51.4-0.2-102.7,0-154.1c0.1-28.5,16.5-49.9,42.6-55.7c5-1.1,10.4-1.3,15.6-1.3c75.2-0.1,150.5-0.1,225.7,0
	c35.7,0,58.2,22.5,58.3,58.4c0.1,50.7,0.1,101.3,0,152c-0.1,35.7-22.7,58.1-58.5,58.1C436.2,494.3,398.7,494.3,361.2,494.3z
	 M503.5,273.5c-0.7-0.5-1.5-0.9-2.2-1.4c-1.2,1.9-2.1,4.1-3.7,5.7c-29.9,31.4-59.9,62.8-90,94.2c-27.6,28.9-65.4,28.8-93-0.2
	c-30-31.4-60-62.8-90-94.2c-1.5-1.6-2.3-3.9-3.4-5.9c-0.8,0.6-1.7,1.2-2.5,1.8c-0.3,2.9-0.8,5.7-0.8,8.6c0,52.1-0.1,104.1,0,156.2
	c0,17,11.2,28.1,28.3,28.1c76.6,0,153.2,0.1,229.9,0c17,0,28.2-11.1,28.2-28.2c0.1-52.1,0-104.1,0-156.2
	C504.4,279.2,503.8,276.4,503.5,273.5z M240.8,253.9c2.4,2.6,3.8,4.3,5.3,5.9c29.8,31.2,59.7,62.5,89.6,93.7
	c15.5,16.2,35.3,16.3,50.8,0.2c30.2-31.5,60.4-63.1,90.5-94.6c1.3-1.4,2.4-2.9,4.2-5.1C400.9,253.9,321.6,253.9,240.8,253.9z" className={"svg-fill-light"}/>
            </svg>
        </Link>
    )
}

export default ContactButton;
