import React from "react";
import Icon from "./Icon";
import {useEffect} from "react";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {GatsbyImage} from "gatsby-plugin-image";

const Photography = ({image, index, project}) => {


    const boxVariant = {
        visible: {
            opacity: 1, y: 0, transition: {
                ease: "easeInOut",
                duration: .5,
                delay: index * 0.2
            }
        },
        hidden: {
            opacity: 0, y: 100, transition: {
                ease: "easeInOut",
                duration: .5
            }
        },
    }

    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            //control.start("hidden");
        }
    }, [control, inView]);

    return (
        <motion.div ref={ref}
                    variants={boxVariant}
                    initial="hidden"
                    animate={control}  className={"photography__container"}>

            <GatsbyImage image={image.asset.gatsbyImageData} alt={"IJOB Photography"}></GatsbyImage>
            <div className="photography__overlay">
                <div className="text">
                    <h3>{project.title}</h3>
                    <p>{project.projctinfo}</p>
                </div>
            </div>
        </motion.div>
    )
}

export default Photography;
