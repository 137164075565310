import React, {useEffect} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import Client from "./Client";
import Marquee from "react-fast-marquee";
import MyPortableText from "./MyPortableText";

const ClientContainer = ({clients, title}) => {


    return (
        <div className="clients__outer">
            <div className="cornerrow top">
                <div className="corner left top"></div>
                <div className="corner right top"></div>
            </div>
            <div className="clients__inner">
                <div className="centered">
                    <MyPortableText value={title}></MyPortableText>
                </div>
                <div className="clients__images desktop">
                    <Marquee gradient={true} speed={100} gradientWidth={100}>
                        {clients.map((client, index) => {
                            return (
                                <a href={client.link ? client.link : ""} target={"_blank"} className="client__image"><GatsbyImage image={client.asset.gatsbyImageData} alt={"Happy Client"}></GatsbyImage></a>
                            );
                        })}
                    </Marquee>
                </div>
                <div className="clients__images mobile">
                    <Marquee gradient={true} speed={30} gradientWidth={20}>
                        {clients.slice(0, clients.length/2).map((client, index) => {
                            return (
                                <a href={client.link ? client.link : ""} target={"_blank"} className="client__image"><GatsbyImage image={client.asset.gatsbyImageData} alt={"Happy Client"}></GatsbyImage></a>
                            );
                        })}
                    </Marquee>
                    <Marquee gradient={true} speed={30} gradientWidth={20} direction={"right"}>
                        {clients.slice(clients.length/2).map((client, index) => {
                            return (
                                <a href={client.link ? client.link : ""} target={"_blank"} className="client__image"><GatsbyImage image={client.asset.gatsbyImageData} alt={"Happy Client"}></GatsbyImage></a>
                            );
                        })}
                    </Marquee>

                </div>
            </div>
            <div className="cornerrow bottom">
                <div className="corner left bottom"></div>
                <div className="corner right bottom"></div>
            </div>
        </div>
    )
}

export default ClientContainer;

/*
{clients.map((client,index) => {
                    return (
                        <Client client={client} delay={index}></Client>
                        );
                })}
 */
