import * as React from "react"
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import WorkContainer from "../components/WorkContainer";
import FactContainer from "../components/FactContainer";
import ServicesContainer from "../components/ServicesContainer";
import PhotographyContainer from "../components/PhotographyContainer";
import TestimonialContainer from "../components/TestimonialContainer";
import AboutContainer from "../components/AboutContainer";
import ClientContainer from "../components/ClientContainer";
import ContactContainer from "../components/ContactContainer";
import localize from "../components/localize";
import ContactButton from "../components/ContactButton";

export const query = graphql`
    {
        allSanityImpressum{
            edges {
                node {
                    title{
                        _type
                        en
                        de
                    }
                }
            }
        }
        allSanityDatenschutz{
            edges {
                node {
                    title{
                        _type
                        en
                        de
                    }
                }
            }

        }
        allSanityStartseite {
            edges {
                node {
                    seo{
                        seo_title{
                            _type
                            en
                            de
                        }
                        seo_image {asset{gatsbyImageData}}
                        seo_description{
                            _type
                            en
                            de
                        }
                    }
                    aboutimage {
                        asset {
                            gatsbyImageData
                        }
                    }
                    _rawAbouttext
                    clientimages {
                        asset {
                            gatsbyImageData
                        }
                        link
                    }
                    contactbtn{
                        _type
                        en
                        de
                    }
                    _rawContactheadline
                    contactimage {
                        asset {
                            gatsbyImageData
                        }
                    }
                    contactvideo
                    facts {
                        _rawContent
                        prozent
                    }
                    image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    video
                    _rawStartseitetitle
                    testimonials {
                        _rawContent
                        author{
                            _type
                            en
                            de
                        }
                    }
                    services {
                        text{
                            _type
                            en
                            de
                        }
                        video{
                            asset{
                                url
                            }
                        }
                        icon
                        headline{
                            _type
                            en
                            de
                        }
                        btntext{
                            _type
                            en
                            de
                        }
                        emailtext{
                            _type
                            en
                            de
                        }
                    }
                    photography{
                        title {
                            de
                            en
                            _type
                        }
                        projctinfo {
                            de
                            en
                            _type
                        }
                        images {
                            asset {
                                gatsbyImageData
                            }
                        }
                    }
                    servicesheadline{
                        _type
                        en
                        de
                    }
                    aboutheadline{
                        _type
                        en
                        de
                    }
                    photoheadline{
                        _type
                        en
                        de
                    }
                    photomehranzeigen{
                        _type
                        en
                        de
                    }
                    videoheadline{
                        _type
                        en
                        de
                    }
                    factsheadline{
                        _type
                        en
                        de
                    }
                    photographytext{
                        _type
                        en
                        de
                    }
                    videotext{
                        _type
                        en
                        de
                    }
                    startseitebtntext{
                        _type
                        en
                        de
                    }
                    _rawClientheadline
                    workvideos {
                        video
                        title {
                            de
                            en
                            _type
                        }
                        cover { asset { gatsbyImageData}}
                        category{
                            title {
                                de
                                en
                                _type
                            }
                            identification
                        }
                    }
                }
            }
        }
        allSanitySettings {
            edges {
                node {
                    telefon
                    sociallinks {
                        value
                        type
                        title
                    }
                    email
                    address_street
                    address_plz
                    address_name
                    address_nr
                    address_ort
                }
            }
        }
        site {
            siteMetadata {
                lang{
                    _type
                    en
                    de
                }
                contact{
                    _type
                    en
                    de
                }
                cookietext1{
                    _type
                    en
                    de
                }
                cookietext2{
                    _type
                    en
                    de
                }
                cookietextdata{
                    _type
                    en
                    de
                }
                seeall{
                    _type
                    en
                    de
                }
                cookiebtn{
                    _type
                    en
                    de
                }
                showmore{
                    _type
                    en
                    de
                }
                showless{
                    _type
                    en
                    de
                }
            }
        }
        allSanityCategories{
            edges{
                node{
                    identification
                    title{
                        en
                        de
                        _type
                    }
                }
            }
        }
    }`;

const IndexPage = ({data, location}) => {

    const page = data.allSanityStartseite.edges[0].node;
    const settings = data.allSanitySettings.edges[0].node;
    const metadata = data.site.siteMetadata;

    const categories = data.allSanityCategories.edges;

    return (
        <Layout settings={settings} location={location} metadata={metadata} servicesheadline={page.servicesheadline} videoheadline={page.videoheadline} photoheadline={page.photoheadline} aboutheadline={page.aboutheadline} lang={metadata.lang} animated={true} privacy={data.allSanityDatenschutz.edges[0].node.title} imprint={data.allSanityImpressum.edges[0].node.title}>
            <SEO title={page.seo?.seo_title} description={page.seo?.seo_description} image={page.seo?.seo_image} location={location}></SEO>
            <ContactButton contact={metadata.contact} location={location}></ContactButton>
            <Landing video={page.video} image={page.image.asset.gatsbyImageData} title={page._rawStartseitetitle} location={location} startseitebtntext={page.startseitebtntext} cookietext1={metadata.cookietext1} cookietext2={metadata.cookietext2} cookiedata={metadata.cookietextdata} cookiebtn={metadata.cookiebtn}></Landing>
            <ServicesContainer location={location} title={page.servicesheadline} services={page.services}></ServicesContainer>
            <WorkContainer loadmore={page.photomehranzeigen} categories={categories} image={page.image.asset.gatsbyImageData} seeall={metadata.seeall} title={page.videoheadline} text={page.videotext} videos={page.workvideos}></WorkContainer>
            <FactContainer title={page.factsheadline} facts={page.facts}></FactContainer>
            <PhotographyContainer loadmore={page.photomehranzeigen} photography={page.photography} text={page.photographytext}></PhotographyContainer>
            <TestimonialContainer testimonials={page.testimonials}></TestimonialContainer>
            <AboutContainer title={page.aboutheadline} image={page.aboutimage} text={page._rawAbouttext} metadata={metadata} location={location}></AboutContainer>
            <ClientContainer title={page._rawClientheadline} clients={page.clientimages}></ClientContainer>
            <ContactContainer headline={page._rawContactheadline} btnText={page.contactbtn} image={page.contactimage} video={page.contactvideo} location={location}></ContactContainer>
        </Layout>)
}

export default localize(IndexPage)

//            <ContactButton contact={metadata.contact} location={location}></ContactButton>

//            <WorkContainer videos={page.videos}></WorkContainer>
//            <PhotographyContainer photography={page.photography} text={page.photographytext}></PhotographyContainer>


/*
            <WorkContainer loadmore={page.photomehranzeigen} categories={categories} image={page.image.asset.gatsbyImageData} seeall={metadata.seeall} title={page.videoheadline} text={page.videotext} videos={page.workvideos}></WorkContainer>

<div ref={dotOutline} className="cursor-dot-outline"></div>
            <div ref={dot} className="cursor-dot"></div>

const delay = 10;
    const cursorVisible = useRef(true);
    const cursorEnlarged = useRef(false);

    const endX = useRef(window.innerWidth/2);
    const endY = useRef(window.innerHeight/2);
    const _x = useRef(0);
    const _y = useRef(0);

    const requestRef = useRef(null);


    const dot = useRef(null);
    const dotOutline = useRef(null);

    const toggleCursorVisibility = () => {
        if(cursorVisible.current){
            dot.current.style.opacity = 1;
            dotOutline.current.style.opacity = 1;
        }
        else{
            dot.current.style.opacity = 0;
            dotOutline.current.style.opacity = 0;
        }
    };

    const toggleCursorSize = () => {
        if(cursorEnlarged.current){
            dot.current.style.transform = "translate(-50%, -50%) scale(.75)";
            dotOutline.current.style.transform = "translate(-50%, -50%) scale(1.5)";
        }
        else{
            dot.current.style.transform = "translate(-50%, -50%) scale(1)";
            dotOutline.current.style.transform = "translate(-50%, -50%) scale(1)";
        }
    }

    const mouseOverEvent = () => {
        cursorEnlarged.current = true;
        toggleCursorSize();
    }
    const mouseOutEvent = () => {
        cursorEnlarged.current = false;
        toggleCursorSize();
    }

    const mouseEnterEvent = () => {
        cursorVisible.current = true;
        toggleCursorVisibility();
    }
    const mouseLeaveEvent = () => {
        cursorVisible.current = false;
        toggleCursorVisibility();
    }

    const mouseMoveEvent = e => {
        cursorVisible.current = true;
        toggleCursorVisibility();
        endX.current = e.pageX;
        endY.current = e.pageY;

        dot.current.style.top = endY.current + "px";
        dot.current.style.left = endX.current + "px";
    }

    const animateDotOutline = () =>{
        _x.current += (endX.current - _x.current) / delay;
        _y.current += (endY.current - _y.current) / delay;

        dotOutline.current.style.top = _y.current + "px";
        dotOutline.current.style.left = _x.current + "px";

        requestRef.current = requestAnimationFrame(animateDotOutline);
    }

    useEffect(() => {
        document.addEventListener('mousedown', mouseOverEvent);
        document.addEventListener('mouseup', mouseOutEvent);
        document.addEventListener('mousemove', mouseMoveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        document.addEventListener('mouseleave', mouseLeaveEvent);

        animateDotOutline();
        return () => {
            document.removeEventListener('mousedown', mouseOverEvent);
            document.removeEventListener('mouseup', mouseOutEvent);
            document.removeEventListener('mousemove', mouseMoveEvent);
            document.removeEventListener('mouseenter', mouseEnterEvent);
            document.removeEventListener('mouseleave', mouseLeaveEvent);

            cancelAnimationFrame(requestRef.current);
        }
    })

 */
