import React, {useState} from 'react';
import Div100vh from 'react-div-100vh'
import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "./MyPortableText";
import {Link} from "gatsby";
import {localeLink} from "../util";
import YouTube from "react-youtube";


const ContactContainer = ({headline, btnText, image, location, video}) => {

    const [videoVisible, setVideoVisible] = useState(false)

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            playsinline: 1,
            mute: 1,
            loop: 1,
            playlist: video
        },
    };

    return (
        <div className="contact__outer div100" id={"contact"}>
            <GatsbyImage className={"fullbackgrounddiv"} image={image.asset.gatsbyImageData} alt={"Contact Image"}></GatsbyImage>
            {video !== null ? <div className="video-container">
                <div className={"iframeoverlay"}></div>
                <YouTube videoId={video} opts={opts} onPlay={() => {setVideoVisible(true)}} className={videoVisible ? "fullbackgrounddiv video visible" : "fullbackgrounddiv video"}/>
            </div> : <></>}

            <div className="landing__inner">
                <MyPortableText className="color-light" value={headline}></MyPortableText>
                <Link to={localeLink('/contact', location)} className="btn btn-red hover-moveup btn-index-contact">{btnText}</Link>
            </div>
        </div>
    );
}

export default ContactContainer;
