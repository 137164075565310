import React, {useState} from "react";
import {Pagination, Navigation, Autoplay} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import MyPortableText from "./MyPortableText";

const TestimonialContainer = ({testimonials}) => {

    const [currentSlide, changeCurrentSlide] = useState(1);


    return (
        <div className="testimonials__outer">
            <div className="cornerrow top">
                <div className="corner left top"></div>
                <div className="corner right top"></div>
            </div>
            <div className="testimonials__inner">
                <Swiper
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    navigation={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                    onSwiper={(swiper) => changeCurrentSlide(swiper.realIndex+1)}
                    onSlideChange={(swiper) => changeCurrentSlide(swiper.realIndex+1)}>
                    {testimonials.map((el, index) => {
                        return (<SwiperSlide className={"testimonial__slide"}>
                            <MyPortableText value={el._rawContent}></MyPortableText>
                            <p className={"testimonialauthor"}>{el.author}</p>
                        </SwiperSlide>);
                    })}
                    <p className={"centered color-lightgrey"}>({currentSlide}/{testimonials.length})</p>
                </Swiper>
            </div>
            <div className="cornerrow bottom">
                <div className="corner left bottom"></div>
                <div className="corner right bottom"></div>
            </div>
        </div>
    )
}

export default TestimonialContainer;

//                    onSlideChange={(swiper) => {count = swiper.realIndex + 1 / swiper.slideCount; console.log(count);}}>
