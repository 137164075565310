import {useEffect} from "react";
import React from "react";

const Filter = ({allVideos, setFiltered, activeCat, setActiveCat, seeall, categories, setNext}) => {


    useEffect(() => {
        if(activeCat === 0){
            setFiltered(allVideos);
            return;
        }
        const filtered = allVideos.filter((video) => {
            let foundCat = false;
            video.category.map(cat => {
                if(cat.identification === activeCat){
                    foundCat = true;
                }
            })
            return foundCat;
        }
        );
        setFiltered(filtered);
        setNext(10);
    }, [activeCat])

    return (
        <div className="category__inner">
            <div className={activeCat === 0 ? "active category hover-moveup" : "category hover-moveup"} onClick={() => {setActiveCat(0)}}>{seeall}</div>
            {categories.map(cat => {
                return <div className={activeCat === cat.node.identification ? "active category hover-moveup" : "category hover-moveup"} onClick={() => {setActiveCat(cat.node.identification)}}>{cat.node.title}</div>
            })}
        </div>
    )
}

export default Filter;
