import React, {useEffect, useState} from "react";
import YouTube from "react-youtube";
import Filter from "./Filter";
import {motion, AnimatePresence} from "framer-motion";
import {GatsbyImage} from "gatsby-plugin-image";
import YTVideo from "./YTVideo";
import Photography from "./Photography";

const WorkContainer = ({
                           videos,
                           seeall,
                           title,
                           text, image, categories, loadmore
                       }) => {

    const [allVideos, setAllVideos] = useState(videos);
    const [filtered, setFiltered] = useState(allVideos);
    const [activeCat, setActiveCat] = useState(0);

    const rows = 10;

    const [next, setNext] = useState(rows);

    const handleMoreVideos = () => {
        setNext(next + rows);
    };

    return (
        <div className="work__outer bg-carbon" id={"videography"}>
            <div className="work__inner">
                <h3 className={"color-light"}>{title}</h3>
                <p className={"color-light introtext"}>{text}</p>
                <div className="category__outer">
                    <Filter setNext={setNext} seeall={seeall} categories={categories} allVideos={allVideos} setFiltered={setFiltered} activeCat={activeCat} setActiveCat={setActiveCat}></Filter>
                </div>
                <div className="workvideos__container">
                    {filtered?.slice(0, next)?.map((vid, index) => {
                        return <div className={index % 2 ? "yt__container fade-in right" : "yt__container fade-in left"}>
                            <YTVideo id={vid.video}></YTVideo>
                            <div className="yt__bg">
                                <GatsbyImage image={vid.cover.asset.gatsbyImageData} alt={"video"} className={"fade-in"}></GatsbyImage>
                            </div>
                        </div>
                    })}
                    {next < filtered?.length && (
                        <div
                            className="loadmore color-light hover-underline"
                            onClick={handleMoreVideos}
                        >
                            {loadmore}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WorkContainer;

/*
<YouTube className="" videoId={vid.video} opts={opts}/>

{videosArray.map((vid, index) => {
                        return <div className={index%2 ? "yt__container right" : "yt__container left"}><YouTube className="" videoId={vid.video} /></div>
                    })}
 */

/*
const [videosArray, filterVideosArray] = useState(videos);
    const [activeFilter, setActiveFilter] = useState('all');

    const filterVideos = (filter) => {
        console.log(filter);
        setActiveFilter(filter);
        if(filter === 'all'){
            console.log(videos);
            filterVideosArray(videos);
            console.log(videosArray);
        }
        else{
            console.log(videos);
            filterVideosArray(videos.filter(vid => {
                return vid.category === filter;
            }))
            console.log(videosArray);

        }
    };
 */

/*
<div className={activeFilter === 'all' ? "category hover-moveup active" : "category hover-moveup"} onClick={() => {filterVideos('all')}}>Show all</div>
                        <div className={activeFilter === 'ads' ? "category hover-moveup active" : "category hover-moveup"} onClick={() => {filterVideos('ads')}}>{video1title}</div>
                        <div className={activeFilter === 'emotions' ? "category hover-moveup active" : "category hover-moveup"} onClick={() => {filterVideos('emotions')}}>Emotions</div>
                        <div className={activeFilter === 'adventure' ? "category hover-moveup active" : "category hover-moveup"} onClick={() => {filterVideos('adventure')}}>Adventure</div>

 */
