import React from "react";
import Service from "./Service";

const ServicesContainer = ({services, title, location}) => {
    return (
        <div className="services__outer" id={"services"} >
            <div className="cornerrow top">
                <div className="corner left top"></div>
                <div className="corner right top"></div>
            </div>
            <h3 className={"color-red"}>{title}</h3>
            <div className="services__inner">
                {services.map((el, index) => {
                    return (<Service delay={index} service={el} location={location}></Service>);
                })}
            </div>
            <div className="cornerrow bottom">
                <div className="corner left bottom"></div>
                <div className="corner right bottom"></div>
            </div>
        </div>
    )
}

export default ServicesContainer;
