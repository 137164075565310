import React, {useState} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import Photography from "./Photography";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import Fact from "./Fact";

const PhotographyContainer = ({photography, text, loadmore}) => {

    const breakpoint = useBreakpoint();

    const rows = 3;

        const [next, setNext] = useState(rows);
        const handleMoreImage = () => {
            setNext(next + rows);
        };

        return (
            <div className="photography__outer bg-carbon" id={"photography"}>
                <div className="photography__inner">
                    <h3 className={"color-light"}>Photography</h3>
                    <p className={"color-light introtext"}>{text}</p>
                    <div className="photography__outer__container">
                        {photography?.slice(0, next)?.map((project, index) => {
                            if(breakpoint.sm){
                                return (
                                    <Swiper
                                        className="mySwiper photo__swiper"
                                        loop={true}
                                        navigation={false}
                                        slidesPerView={"auto"}
                                        centeredSlides={true}
                                        spaceBetween={20}>
                                        {
                                        project.images.map((image, index) => {
                                            return <SwiperSlide className={"photo__slide"}>
                                                <Photography image={image} index={index} project={project}></Photography>
                                            </SwiperSlide>
                                        })
                                    }
                                    </Swiper>)
                            }else{
                                return (
                                    project.images.map((image, index) => {
                                        return <Photography image={image} index={index} project={project}></Photography>
                                    }))
                            }
                        })}
                        {next < photography?.length && (
                            <div
                                className="loadmore color-light hover-underline"
                                onClick={handleMoreImage}
                            >
                                {loadmore}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    export default PhotographyContainer;

/*
return <div className="photography__container"><GatsbyImage image={pic.asset.gatsbyImageData} alt={"IJOB Photography"}></GatsbyImage></div>;

 */
